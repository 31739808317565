class Counter {
  constructor(el, callback = () => {}) {
    this.counter = el;
    this.callback = callback;
    this.endDate = new Date(el.dataset.counter).getTime();
    this.days = 0;
    this.hours = 0;
    this.mins = 0;
    this.secs = 0;
    this.value = null;

    this.assignEvents();
  }

  assignEvents() {
    this.startCounter();
  }

  startCounter() {
    this.tick();
  }

  tick() {
    const now = new Date().getTime();
    const distance = this.endDate - now;

    if (distance < 1000) {
      this.counter.style.display = "none";

      this.callback();

      return;
    }

    this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
    this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.mins = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    this.secs = Math.floor((distance % (1000 * 60)) / 1000);
    this.value = '';

    if (this.days > 0) {
      this.value += `${this.days} ${this.days > 1 ? 'dni' : 'dzień'}, `;
    }

    if (this.hours > 0) {
      this.value += `${this.hours} godz. `;
    }

    if (this.mins > 0) {
      this.value += `${this.mins} min. `;
    }

    this.value += `${this.secs} sek.`;

    this.counter.innerText = this.value;

    setTimeout(this.tick.bind(this), 1000);
  }
}

export { Counter };
