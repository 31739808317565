import domReady from '@roots/sage/client/dom-ready';
import Swiper from 'swiper/bundle';
import SimpleLightbox from 'simple-lightbox';
import { mobileNav } from './modules/nav-scripts.js';
import { Counter } from './modules/counter.js';
import {
  toggleIndividualCheckbox,
  toggleDesignerFields,
  toggleCompanyFields,
  toggleVatPayerFields,
} from './modules/forms.js';
import {
  handleSubmit,
  calculateAmountReceived,
  addRoom,
} from './modules/add-project-scripts.js';
import { handleGiveUserDiscount } from './modules/single-product-scripts.js';
import { uploadRoomTechnicalDrawingFile } from './modules/checkout-scripts.js';
import {
  makeIndividualTechnicalDrawings,
  leaveIndividualTechnicalDrawings,
  handleSendIndividualTechnicalDrawings,
  acceptIndividualTechnicalDrawings,
  handleRejectIndividualTechnicalDrawings,
  addInvoiceBill,
  acceptInvoiceBill,
  withdrawAvailableFunds,
  changeLogo,
  formatBankNumber,
} from './modules/my-account-scripts.js';
import { handleLikeToggle } from './modules/like-scripts.js';

/**
 * Application entrypoint
 */
domReady(async () => {
  const nav = document.querySelector('.nav');
  const burger = document.querySelector('.burger');
  const heroSlider = document.querySelector('.hero-slider');
  const offerVisible = document.querySelector('.offer--visible');
  const timer = document.querySelector('.timer');
  const addProjectForm = document.querySelector('.add-project-form');
  const fieldPrice = document.querySelector('.field--price');
  const addRoomButton = document.querySelector('.add-room');
  const productsSliders = document.querySelectorAll('.products-slider');
  const likeButtons = document.querySelectorAll('.like-button');
  const goldButton = document.querySelector('.gold-button');
  const userType = document.querySelector('#user-type');
  const hasCompany = document.querySelector('#has-company');
  const isVatPayer = document.querySelector('#is-vat-payer');
  const productsListHeaderFiltersButton = document.querySelector('.products-list-header__filters-button');
  const filters = document.querySelector('.filters');
  const filtersClose = document.querySelector('.filters__close');
  const fieldGroupButtons = document.querySelectorAll('.field-group-button');
  const roomTechnicalDrawingFiles = document.querySelectorAll('.room-technical-drawing-file');
  const makeIndividualTechnicalDrawingsButtons = document.querySelectorAll('.make-individual-technical-drawings');
  const leaveIndividualTechnicalDrawingsButtons = document.querySelectorAll('.leave-individual-technical-drawings');
  const sendIndividualTechnicalDrawingsForms = document.querySelectorAll('.send-individual-technical-drawings-form');
  const acceptIndividualTechnicalDrawingsButtons = document.querySelectorAll('.accept-individual-technical-drawings');
  const rejectIndividualTechnicalDrawingsForms = document.querySelectorAll('.reject-individual-technical-drawings-form');
  const addInvoiceBillButton = document.querySelector('.add-invoice-bill');
  const acceptInvoiceBillButton = document.querySelector('.accept-invoice-bill');
  const withdrawAvailableFundsButton = document.querySelector('.withdraw-available-funds');
  const changeLogoButton = document.querySelector('.edit-account-section__change-logo');
  const bankNumberInput = document.querySelector('.bank-number-input');

  const individualCheckbox = () => {
    document.querySelectorAll('.checkbox--individual').forEach(checkbox => {
      checkbox.addEventListener('change', toggleIndividualCheckbox);
    });
  }

  if (document.querySelector('.nav__list--links')) {
    mobileNav(nav, burger);
  }

  if (heroSlider) {
    new Swiper(heroSlider, {
      allowTouchMove: false,
      loop: true,
      autoplay: {
        delay: 5000,
      },
    });
  }

  if (offerVisible && timer) {
    new Counter(timer, () => {
      offerVisible.classList.remove('offer--visible');
    });
  }

  if (addProjectForm) {
    addProjectForm.addEventListener('submit', handleSubmit);
  }

  individualCheckbox();

  if (fieldPrice) {
    fieldPrice.addEventListener('keyup', calculateAmountReceived);
  }

  if (addRoomButton) {
    addRoomButton.addEventListener('click', () => {
      addRoom();
      individualCheckbox();
    });
  }

  if (productsSliders) {
    productsSliders.forEach(slider => {
      const sliderSlides = slider.querySelector('.products-slider__slides');
      const swiperButtonNext = slider.querySelector('.swiper-button-next');
      const swiperButtonPrev = slider.querySelector('.swiper-button-prev');

      new Swiper(sliderSlides, {
        slidesPerView: 1,
        breakpoints: {
          321: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1280: {
            slidesPerView: 5,
          },
        },
        navigation: {
          nextEl: swiperButtonNext,
          prevEl: swiperButtonPrev,
        },
      });
    });
  }

  if (document.querySelector('.product__slides') && document.querySelector('.product__thumbs')) {
    const swiperProductThumbs = new Swiper('.product__thumbs', {
      slidesPerView: 'auto',
      spaceBetween: 20,
      virtualTranslate: true,
    });

    new Swiper('.product__slides', {
      slidesPerView: 1,
      loop: true,
      spaceBetween: 30,
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: swiperProductThumbs,
      },
    });

    new SimpleLightbox({
      elements: '.product__slides a',
    });
  }

  if (likeButtons) {
    likeButtons.forEach(button => {
      button.addEventListener('click', () => handleLikeToggle(button));
    });
  }

  if (goldButton) {
    goldButton.addEventListener('click', handleGiveUserDiscount);
  }

  if (userType) {
    toggleDesignerFields(userType);

    userType.addEventListener('change', () => toggleDesignerFields(userType));
  }

  if (hasCompany) {
    toggleCompanyFields(hasCompany);

    hasCompany.addEventListener('change', () => toggleCompanyFields(hasCompany));
  }

  if (isVatPayer) {
    toggleVatPayerFields(isVatPayer);

    isVatPayer.addEventListener('change', () => toggleVatPayerFields(isVatPayer));
  }

  if (productsListHeaderFiltersButton) {
    productsListHeaderFiltersButton.addEventListener('click', () => {
      filters.classList.add('filters--active');
    });
  }

  if (filtersClose) {
    filtersClose.addEventListener('click', () => {
      filters.classList.remove('filters--active');
    });
  }

  if (fieldGroupButtons) {
    fieldGroupButtons.forEach(button => {
      button.addEventListener('click', () => {
        button.classList.toggle('field-group-button--revealed');
      });
    });
  }

  if (roomTechnicalDrawingFiles) {
    roomTechnicalDrawingFiles.forEach(file => {
      file.addEventListener('change', uploadRoomTechnicalDrawingFile);
    });
  }

  if (makeIndividualTechnicalDrawingsButtons) {
    makeIndividualTechnicalDrawingsButtons.forEach(button => {
      button.addEventListener('click', makeIndividualTechnicalDrawings);
    });
  }

  if (leaveIndividualTechnicalDrawingsButtons) {
    leaveIndividualTechnicalDrawingsButtons.forEach(button => {
      button.addEventListener('click', leaveIndividualTechnicalDrawings);
    });
  }

  if (sendIndividualTechnicalDrawingsForms) {
    sendIndividualTechnicalDrawingsForms.forEach(form => {
      form.addEventListener('submit', handleSendIndividualTechnicalDrawings);
    });
  }

  if (acceptIndividualTechnicalDrawingsButtons) {
    acceptIndividualTechnicalDrawingsButtons.forEach(button => {
      button.addEventListener('click', acceptIndividualTechnicalDrawings);
    });
  }

  if (rejectIndividualTechnicalDrawingsForms) {
    rejectIndividualTechnicalDrawingsForms.forEach(form => {
      form.addEventListener('submit', handleRejectIndividualTechnicalDrawings);
    });
  }

  if (addInvoiceBillButton) {
    addInvoiceBillButton.addEventListener('click', addInvoiceBill);
  }

  if (acceptInvoiceBillButton) {
    acceptInvoiceBillButton.addEventListener('click', acceptInvoiceBill);
  }

  if (withdrawAvailableFundsButton) {
    withdrawAvailableFundsButton.addEventListener('click', withdrawAvailableFunds);
  }

  if (changeLogoButton) {
    changeLogoButton.addEventListener('click', changeLogo);
  }

  if (bankNumberInput) {
    bankNumberInput.addEventListener('input', formatBankNumber);
  }
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
