import disableScroll from 'disable-scroll';

const mobileNav = (nav, burger) => {
  burger.addEventListener('click', () => {
    burger.classList.toggle('burger--active');

    const isMenuOpen = burger.classList.contains('burger--active');

    burger.setAttribute('aria-expanded', isMenuOpen);
    nav.classList.toggle('nav--active');

    if (isMenuOpen) {
      disableScroll.on();
    } else {
      disableScroll.off();
    }
  });
}

export { mobileNav };
