/* global app */

const handleLikeToggle = (button) => {
  const formData = new FormData();

  button.disabled = true;

  formData.append('project-id', button.getAttribute('data-project-id'));
  formData.append('period', button.getAttribute('data-period'));

  fetch(`${app.ajax_url}?action=like-toggle`, { method: 'POST', body: formData })
    .then(res => res.json())
    .then(json => {
      if (json.success) {
        const buttonCounter = button.querySelector('.like-button__counter');

        buttonCounter.innerText = json.likes_count;

        if (json.is_liked) {
          button.classList.add('like-button--liked');
        } else {
          button.classList.remove('like-button--liked');
        }

        button.disabled = false;
      }
    })
    .catch(err => console.error('Error:', err));
}

export { handleLikeToggle };
