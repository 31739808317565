/* global app */

import { initFileDrop } from './forms.js';

const woocommerceMyaccountContent = document.querySelector('.woocommerce-MyAccount-content');
const invoiceBillFile = document.querySelector('.invoice-bill-file');
const addInvoiceBillButton = document.querySelector('.add-invoice-bill');
const logoFile = document.querySelector('.logo-file');
const logo = document.querySelector('.edit-account-section__logo');

let dropzoneEls = document.querySelectorAll('.send-individual-technical-drawings-form .dropzone');

const dropzones = [];

if (dropzoneEls) {
  dropzoneEls.forEach(el => {
    dropzones.push(initFileDrop(el));
  });
}

const makeIndividualTechnicalDrawings = (e) => {
  const button = e.target;
  const notification = button.closest('.alert');
  const leaveIndividualTechnicalDrawingsButton = notification.querySelector('.leave-individual-technical-drawings');

  const formData = new FormData();

  const notificationId = notification.getAttribute('data-notification-id');

  button.disabled = true;
  leaveIndividualTechnicalDrawingsButton.disabled = true;

  formData.append('notification-id', notificationId);

  fetch(`${app.ajax_url}?action=make-individual-technical-drawings`, { method: 'POST', body: formData })
    .then(res => res.json())
    .then(json => {
      const alert = notification.querySelector('.alert');

      if (alert) {
        alert.remove();
      }

      if (json.success) {
        const notificationButtons = button.closest('.notification-buttons');
        const notificationMessage = notification.querySelector('.notification-message');

        notificationButtons.remove();

        notificationMessage.innerHTML = json.message;

        const div = document.createElement('div');

        div.innerHTML = `
          <div class="notification-buttons">
            <a class="button button--short button--light" href="${json.room_technical_drawing}" target="_blank">Rysunek techniczny pomieszczenia</a>
          </div>
          <form class="send-individual-technical-drawings-form">
            <input type="hidden" class="field" name="action" value="send-individual-technical-drawings">
            <input type="hidden" class="field" name="notification-id" value="${notificationId}">
            <div class="form-line">
              <label class="field-label">Indywidualne rysunki techniczne (PDF, maksymalnie 5 MB)</label>
              <div class="dropzone dropzone--light" data-name="individual-technical-drawings" data-accept="application/pdf"></div>
            </div>
            <button type="submit" class="button button--short button--primary button--normalcase send-individual-technical-drawings">Wyślij</button>
          </form>
        `;

        notification.appendChild(div);

        const sendIndividualTechnicalDrawingsForm = div.querySelector('.send-individual-technical-drawings-form');

        dropzoneEls = div.querySelectorAll('.dropzone');

        sendIndividualTechnicalDrawingsForm.addEventListener('submit', handleSendIndividualTechnicalDrawings);

        dropzoneEls.forEach(el => {
          dropzones.push(initFileDrop(el));
        });
      } else {
        let content = '';

        const ul = document.createElement('ul');

        ul.classList.add('alert', 'alert--danger');

        json.errors.forEach(error => {
          content += `<li>${error}</li>`;
        });

        ul.innerHTML = content;

        notification.prepend(ul);

        button.disabled = false;
        leaveIndividualTechnicalDrawingsButton.disabled = false;
      }
    })
    .catch(err => console.error('Error:', err));
}

const notFoundNotifications = () => {
  const notifications = woocommerceMyaccountContent.querySelectorAll('.alert--warning');

  if (notifications.length === 0) {
    const div = document.createElement('div');

    div.classList.add('woocommerce-info');

    div.textContent = 'Nie masz żadnych powiadomień.';

    woocommerceMyaccountContent.appendChild(div);
  }
}

const leaveIndividualTechnicalDrawings = (e) => {
  const button = e.target;
  const notification = button.closest('.alert');
  const makeIndividualTechnicalDrawingsButton = notification.querySelector('.make-individual-technical-drawings');

  const formData = new FormData();

  makeIndividualTechnicalDrawingsButton.disabled = true;
  button.disabled = true;

  formData.append('notification-id', notification.getAttribute('data-notification-id'));

  fetch(`${app.ajax_url}?action=leave-individual-technical-drawings`, { method: 'POST', body: formData })
    .then(res => res.json())
    .then(json => {
      const alert = notification.querySelector('.alert');

      if (alert) {
        alert.remove();
      }

      if (json.success) {
        notification.remove();

        notFoundNotifications();
      } else {
        let content = '';

        const ul = document.createElement('ul');

        ul.classList.add('alert', 'alert--danger');

        json.errors.forEach(error => {
          content += `<li>${error}</li>`;
        });

        ul.innerHTML = content;

        notification.prepend(ul);

        makeIndividualTechnicalDrawingsButton.disabled = false;
        button.disabled = false;
      }
    })
    .catch(err => console.error('Error:', err));
}

const handleSendIndividualTechnicalDrawings = (e) => {
  const form = e.target;
  const sendIndividualTechnicalDrawingsButton = form.querySelector('.send-individual-technical-drawings');
  const fields = form.querySelectorAll('.field');

  const formData = new FormData();

  e.preventDefault();

  sendIndividualTechnicalDrawingsButton.disabled = true;

  fields.forEach(field => {
    formData.append(field.getAttribute('name'), field.value);
  });

  dropzones.forEach(dropzone => {
    const { paramName } = dropzone.options;

    dropzone.files.forEach((file, index) => {
      if (file['status'] !== 'error') {
        formData.append(`${paramName}[${index}]`, file);
      }
    });
  });

  fetch(app.ajax_url, { method: 'POST', body: formData })
    .then(res => res.json())
    .then(json => {
      const notification = form.closest('.alert');

      const ul = document.createElement('ul');

      ul.classList.add('alert');

      if (json.success) {
        const woocommerceNoticesWrapper = document.querySelector('.woocommerce-notices-wrapper');
        const alert = woocommerceNoticesWrapper.querySelector('.alert');
        const pageContent = document.querySelector('.page-content');

        notification.remove();

        if (alert) {
          alert.remove();
        }

        ul.classList.add('alert--success');

        ul.innerHTML = '<li>Twoje pliki zostały przesłane. Dziękujemy!</li>';

        woocommerceNoticesWrapper.appendChild(ul);

        pageContent.scrollIntoView();

        notFoundNotifications();
      } else {
        const alert = notification.querySelector('.alert');

        let content = '';

        if (alert) {
          alert.remove();
        }

        ul.classList.add('alert--danger');

        json.errors.forEach(error => {
          content += `<li>${error}</li>`;
        });

        ul.innerHTML = content;

        notification.prepend(ul);
      }

      sendIndividualTechnicalDrawingsButton.disabled = false;
    })
    .catch(err => console.error('Error:', err));
}

const acceptIndividualTechnicalDrawings = (e) => {
  const button = e.target;
  const notification = button.closest('.alert');
  const rejectIndividualTechnicalDrawingsButton = notification.querySelector('.reject-individual-technical-drawings');

  const formData = new FormData();

  button.disabled = true;
  rejectIndividualTechnicalDrawingsButton.disabled = true;

  formData.append('notification-id', notification.getAttribute('data-notification-id'));

  fetch(`${app.ajax_url}?action=accept-individual-technical-drawings`, { method: 'POST', body: formData })
    .then(res => res.json())
    .then(json => {
      const alert = notification.querySelector('.alert');

      if (alert) {
        alert.remove();
      }

      if (json.success) {
        const notificationButtons = button.closest('.notification-buttons');
        const rejectIndividualTechnicalDrawingsForm = notification.querySelector('.reject-individual-technical-drawings-form');
        const notificationMessage = notification.querySelector('.notification-message');

        notificationButtons.remove();
        rejectIndividualTechnicalDrawingsForm.remove();

        notificationMessage.innerHTML = json.message;
      } else {
        let content = '';

        const ul = document.createElement('ul');

        ul.classList.add('alert', 'alert--danger');

        json.errors.forEach(error => {
          content += `<li>${error}</li>`;
        });

        ul.innerHTML = content;

        notification.prepend(ul);
      }
    })
    .catch(err => console.error('Error:', err));
}

const handleRejectIndividualTechnicalDrawings = (e) => {
  const form = e.target;
  const fields = form.querySelectorAll('.field');
  const notification = form.closest('.alert');
  const acceptIndividualTechnicalDrawingsButton = notification.querySelector('.accept-individual-technical-drawings');
  const rejectIndividualTechnicalDrawingsButton = notification.querySelector('.reject-individual-technical-drawings');

  const formData = new FormData();

  e.preventDefault();

  acceptIndividualTechnicalDrawingsButton.disabled = true;
  rejectIndividualTechnicalDrawingsButton.disabled = true;

  fields.forEach(field => {
    formData.append(field.getAttribute('name'), field.value);
  });

  fetch(app.ajax_url, { method: 'POST', body: formData })
    .then(res => res.json())
    .then(json => {
      const alert = notification.querySelector('.alert');

      if (alert) {
        alert.remove();
      }

      if (json.success) {
        const notificationButtons = acceptIndividualTechnicalDrawingsButton.closest('.notification-buttons');
        const notificationMessage = notification.querySelector('.notification-message');

        notificationButtons.remove();
        form.remove();

        notificationMessage.innerHTML = json.message;
      } else {
        let content = '';

        const ul = document.createElement('ul');

        ul.classList.add('alert', 'alert--danger');

        json.errors.forEach(error => {
          content += `<li>${error}</li>`;
        });

        ul.innerHTML = content;

        notification.prepend(ul);

        acceptIndividualTechnicalDrawingsButton.disabled = false;
        rejectIndividualTechnicalDrawingsButton.disabled = false;
      }
    })
    .catch(err => console.error('Error:', err));
}

const addInvoiceBill = () => {
  invoiceBillFile.click();
}

const sendInvoiceBill = () => {
  const formData = new FormData();

  addInvoiceBillButton.disabled = true;

  formData.append('invoice-bill', invoiceBillFile.files[0]);

  fetch(`${app.ajax_url}?action=send-invoice-bill`, { method: 'POST', body: formData })
    .then(res => res.json())
    .then(json => {
      const alert = woocommerceMyaccountContent.querySelector('.alert');

      if (alert) {
        alert.remove();
      }

      const ul = document.createElement('ul');

      ul.classList.add('alert');

      let content = '';

      if (json.success) {
        ul.classList.add('alert--success');

        content = '<li>Twoja faktura/rachunek został przesłany. Dziękujemy!</li>';

        addInvoiceBillButton.remove();
      } else {
        ul.classList.add('alert', 'alert--danger');

        json.errors.forEach(error => {
          content += `<li>${error}</li>`;
        });

        addInvoiceBillButton.disabled = false;
      }

      ul.innerHTML = content;

      woocommerceMyaccountContent.prepend(ul);
    })
    .catch(err => console.error('Error:', err));
}

const acceptInvoiceBill = (e) => {
  const button = e.target;
  const notification = button.closest('.alert');

  const formData = new FormData();

  button.disabled = true;

  formData.append('notification-id', notification.getAttribute('data-notification-id'));

  fetch(`${app.ajax_url}?action=accept-invoice-bill`, { method: 'POST', body: formData })
    .then(res => res.json())
    .then(json => {
      const alert = notification.querySelector('.alert');

      if (alert) {
        alert.remove();
      }

      if (json.success) {
        notification.remove();

        notFoundNotifications();
      } else {
        let content = '';

        const ul = document.createElement('ul');

        ul.classList.add('alert', 'alert--danger');

        json.errors.forEach(error => {
          content += `<li>${error}</li>`;
        });

        ul.innerHTML = content;

        notification.prepend(ul);

        button.disabled = false;
      }
    })
    .catch(err => console.error('Error:', err));
}

const withdrawAvailableFunds = (e) => {
  const button = e.target;

  button.disabled = true;

  fetch(`${app.ajax_url}?action=withdraw-available-funds`, { method: 'POST' })
    .then(res => res.json())
    .then(json => {
      const alert = woocommerceMyaccountContent.querySelector('.alert');

      if (alert) {
        alert.remove();
      }

      const ul = document.createElement('ul');

      ul.classList.add('alert');

      let content = '';

      if (json.success) {
        const woocommerceInfo = button.closest('.woocommerce-info');
        const amountBdi = woocommerceInfo.querySelector('.amount bdi');

        ul.classList.add('alert--success');

        content = '<li>Wypłata środków została zlecona. Dziękujemy!</li>';

        amountBdi.innerHTML = '0,00 <span class="woocommerce-Price-currencySymbol">zł</span>';
      } else {
        ul.classList.add('alert', 'alert--danger');

        json.errors.forEach(error => {
          content += `<li>${error}</li>`;
        });
      }

      ul.innerHTML = content;

      woocommerceMyaccountContent.prepend(ul);

      button.disabled = false;
    })
    .catch(err => console.error('Error:', err));
}

const changeLogo = () => {
  logoFile.click();
}

const uploadLogoFile = (e) => {
  const input = e.target;

  logo.src = URL.createObjectURL(input.files[0]);
}

const formatBankNumber = (e) => {
  const input = e.target;

  const value = input.value.replace(/\D/g, '').substring(0, 26);
  const arr = value.split('');

  for (let i = 3; i <= arr.length; i += 5) {
    if (arr.length >= i) {
      arr.splice(i - 1, 0, ' ');
    }
  }

  input.value = arr.join('');
}

if (invoiceBillFile) {
  invoiceBillFile.addEventListener('change', sendInvoiceBill);
}

if (logoFile) {
  logoFile.addEventListener('change', uploadLogoFile);
}

export {
  makeIndividualTechnicalDrawings,
  leaveIndividualTechnicalDrawings,
  handleSendIndividualTechnicalDrawings,
  acceptIndividualTechnicalDrawings,
  handleRejectIndividualTechnicalDrawings,
  addInvoiceBill,
  acceptInvoiceBill,
  withdrawAvailableFunds,
  changeLogo,
  formatBankNumber,
};
