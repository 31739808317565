import Dropzone from 'dropzone';

const cooperationInputProvision = document.querySelector('#cooperation [value="provision"]');

const designerFields = [
  {
    'container': document.querySelector('.first-name'),
    'input': document.querySelector('#first-name'),
  },
  {
    'container': document.querySelector('.last-name'),
    'input': document.querySelector('#last-name'),
  },
  {
    'container': document.querySelector('.has-company'),
    'input': document.querySelector('#has-company'),
  },
  {
    'container': document.querySelector('.cooperation'),
    'input': document.querySelector('#cooperation'),
  },
  {
    'container': document.querySelector('.country'),
    'input': document.querySelector('#country'),
  },
];

const companyFields = [
  {
    'container': document.querySelector('.company'),
    'input': document.querySelector('#company'),
  },
  {
    'container': document.querySelector('.is-vat-payer'),
    'input': document.querySelector('#is-vat-payer'),
  },
];

const vatPayerFields = [
  {
    'container': document.querySelector('.vat-number'),
    'input': document.querySelector('#vat-number'),
  },
];

const initFileDrop = (node) => {
  return new Dropzone(node, {
    url: window.location,
    paramName: node.getAttribute('data-name'),
    maxFilesize: 15,
    maxFiles: 100,
    acceptedFiles: node.getAttribute('data-accept'),
    uploadMultiple: true,
    parallelUploads: 100,
    addRemoveLinks: true,
    dictDefaultMessage: 'Upuść pliki tutaj, aby przesłać',
    dictRemoveFile: 'Usuń plik',
    dictFileTooBig: 'Plik jest za duży ({{filesize}} MB). Maksymalny rozmiar pliku: {{maxFilesize}} MB.',
    dictInvalidFileType: 'Nie możesz przesyłać plików tego typu.',
    dictCancelUpload: 'Anuluj przesyłanie',
    dictCancelUploadConfirmation: 'Czy na pewno chcesz anulować przesyłanie?',
    dictMaxFilesExceeded: 'Nie możesz przesłać więcej plików.',
  });
}

const toggleIndividualCheckbox = (e) => {
  const checkbox = e.target;

  if (checkbox.checked) {
    document.querySelectorAll(`.checkbox[name="${checkbox.getAttribute('name')}"]:checked`).forEach(checkedCheckbox => {
      if (checkedCheckbox !== checkbox) {
        checkedCheckbox.checked = false;
      }
    });
  }
}

const toggleDesignerFields = (userType) => {
  const selectedOption = userType.options[userType.selectedIndex].value;

  if (selectedOption === 'client') {
    [...designerFields, ...companyFields, ...vatPayerFields].forEach(field => {
      field.container.classList.add('hide');

      field.input.value = '';
    });

    cooperationInputProvision.textContent = 'Prowizja od sprzedaży (30% ceny projektu)';
  } else if (selectedOption === 'designer') {
    designerFields.forEach(field => {
      field.container.classList.remove('hide');
    });
  }
}

const toggleCompanyFields = (hasCompany) => {
  const selectedOption = hasCompany.options[hasCompany.selectedIndex].value;

  if (selectedOption === '1') {
    companyFields.forEach(field => {
      if (field.container) {
        field.container.classList.remove('hide');
      }
    });
  } else {
    [...companyFields, ...vatPayerFields].forEach(field => {
      if (field.container && field.input) {
        field.container.classList.add('hide');

        field.input.value = '';
      }
    });

    cooperationInputProvision.textContent = 'Prowizja od sprzedaży (30% ceny projektu)';
  }
}

const toggleVatPayerFields = (isVatPayer) => {
  const selectedOption = isVatPayer.options[isVatPayer.selectedIndex].value;

  if (selectedOption === '1') {
    vatPayerFields.forEach(field => {
      field.container.classList.remove('hide');
    });

    cooperationInputProvision.textContent = 'Prowizja od sprzedaży (20% ceny projektu)';
  } else {
    vatPayerFields.forEach(field => {
      field.container.classList.add('hide');

      field.input.value = '';
    });

    cooperationInputProvision.textContent = 'Prowizja od sprzedaży (30% ceny projektu)';
  }
}

export {
  initFileDrop,
  toggleIndividualCheckbox,
  toggleDesignerFields,
  toggleCompanyFields,
  toggleVatPayerFields,
};
