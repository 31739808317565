/* global app */

import { Counter } from './counter.js';

const offer = document.querySelector('.offer');
const offerDiscount = offer.querySelector('.offer__discount');
const timer = offer.querySelector('.timer');

const handleGiveUserDiscount = (e) => {
  const button = e.target;

  button.disabled = true;

  fetch(`${app.ajax_url}?action=give-user-discount`, { method: 'POST' })
    .then(res => res.json())
    .then(json => {
      if (json.success) {
        button.remove();

        offerDiscount.innerText = json.discount_amount;
        timer.dataset.counter = json.discount_expires;

        new Counter(timer, () => {
          offer.classList.remove('offer--visible');
        });

        offer.classList.add('offer--visible');
      }
    })
    .catch(err => console.error('Error:', err));
}

export { handleGiveUserDiscount };
