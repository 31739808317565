/* global app */

import Dropzone from 'dropzone';
import { initFileDrop } from './forms.js';

const dropzoneEls = document.querySelectorAll('.add-project-form .dropzone');

const dropzones = [];

if (dropzoneEls) {
  dropzoneEls.forEach(el => {
    dropzones.push(initFileDrop(el));
  });
}

const handleSubmit = (e) => {
  const form = e.target;

  const addProjectButton = form.querySelector('.add-project');

  addProjectButton.disabled = true;

  const formData = new FormData();

  e.preventDefault();

  form.querySelectorAll('.field, .checkbox').forEach(field => {
    if (field.getAttribute('type') === 'file') {
      [...field.files].forEach(file => {
        formData.append(field.getAttribute('name'), file);
      });
    } else if (field.getAttribute('type') !== 'checkbox' || (field.getAttribute('type') === 'checkbox' && field.checked)) {
      formData.append(field.getAttribute('name'), field.value);
    }
  });

  dropzones.forEach(dropzone => {
    const { paramName } = dropzone.options;

    dropzone.files.forEach((file, index) => {
      if (file['status'] !== 'error') {
        formData.append(`${paramName}[${index}]`, file);
      }
    });
  });

  fetch(app.ajax_url, { method: 'POST', body: formData })
    .then(res => res.json())
    .then(json => {
      const alert = form.querySelector('.alert');

      if (alert) {
        alert.remove();
      }

      const ul = document.createElement('ul');

      ul.classList.add('alert');

      let content = '';

      if (json.success) {
        ul.classList.add('alert--success');

        content = '<li>Twój projekt został dodany. Dziękujemy!</li>';

        form.reset();

        if (dropzoneEls) {
          dropzoneEls.forEach(el => {
            Dropzone.forElement(el).removeAllFiles(true);
          });
        }

        form.querySelectorAll('.fields:not([data-id="0"])').forEach(room => {
          room.remove();
        });
      } else {
        ul.classList.add('alert--danger');

        json.errors.forEach(error => {
          content += `<li>${error}</li>`;
        });
      }

      ul.innerHTML = content;

      form.prepend(ul);

      document.querySelector('.page-content').scrollIntoView();

      addProjectButton.disabled = false;
    })
    .catch(err => console.error('Error:', err));
}

const formatPrice = (value) => {
  let valueParts = Number(value).toFixed(2).split('.');
  let intValue = valueParts[0];
  let intValueLength = intValue.length;
  let temp = '';

  for (let i = 0; i < intValueLength; i++) {
    temp = `${intValue[intValueLength - i - 1]}${temp}`;

    if (i % 3 === 2) {
      temp = ` ${temp}`;
    }
  }

  temp += `,${valueParts[1]}`;

  return temp;
}

const calculateAmountReceived = (e) => {
  const field = e.target;
  const formLine = field.closest('.form-line');

  const provision = field.value * (app.provision / 100);

  formLine.querySelector('.provision').textContent = formatPrice(provision);
  formLine.querySelector('.amount-received').textContent = formatPrice(field.value - provision);
}

const updateRoomNumbering = () => {
  const roomNumbers = document.querySelectorAll('.room-number');

  for (let i = 1; i < roomNumbers.length; i++) {
    roomNumbers[i].textContent = i + 1;
  }
}

const removeRoom = (e) => {
  const element = e.target;

  element.closest('.fields').remove();

  updateRoomNumbering();
}

const addRoom = () => {
  const projectList = document.querySelector('.project-list');

  const inputId = parseInt(projectList.lastElementChild.getAttribute('data-id')) + 1;
  const div = document.createElement('div');

  div.classList.add('fields');
  div.setAttribute('data-id', inputId);

  let room = `
    <h2 class="fields__title">
      Pomieszczenie
      <span class="room-number">${inputId + 1}</span>
    </h2>
    <div class="fields__box">
      <div class="form-line">
        <label for="name-${inputId}" class="field-label">Nazwa</label>
        <input type="text" class="field" name="projects[${inputId}][name]" id="name-${inputId}">
      </div>
      <div class="form-line">
        <label for="description-${inputId}" class="field-label">Opis</label>
        <textarea class="field" name="projects[${inputId}][description]" id="description-${inputId}" rows="8"></textarea>
      </div>
      <div class="form-line">
        <label class="field-label">Zdjęcia widoczne na stronie (JPEG, JPG, PNG, maksymalnie 15 MB)</label>
        <div class="dropzone" data-name="projects[${inputId}][images]" data-accept="image/jpeg, image/png"></div>
        <span class="field-text">Na miniaturce wyświetlać się będzie pierwsze zdjęcie.</span>
      </div>
      <div class="form-line">
        <label class="field-label">Wizualizacje – dostaje klient po zakupieniu projektu (JPEG, JPG, PNG, maksymalnie 15 MB)</label>
        <div class="dropzone" data-name="projects[${inputId}][visualizations]" data-accept="image/jpeg, image/png"></div>
      </div>
      <div class="form-line">
        <label for="material-list-${inputId}" class="field-label">Lista materiałów (PDF, maksymalnie 5 MB)</label>
        <input type="file" class="field field--file" name="projects[${inputId}][material-list]" id="material-list-${inputId}" accept="application/pdf">
      </div>
      <div class="form-line">
        <label class="field-label">Rysunki techniczne (rzuty, przekroje i wszystkie instalacje danego pomieszczenia) (PDF, maksymalnie 5 MB)</label>
        <div class="dropzone" data-name="projects[${inputId}][technical-drawings]" data-accept="application/pdf"></div>
      </div>
      <div class="form-line">
        <p class="field-group-text">Przeważający materiał</p>
        <div class="form-line__checkboxes">
  `;

  app.project_materials.forEach(material => {
    room += `
      <label class="checkbox-container checkbox-container--tile">
        <input type="checkbox" class="checkbox checkbox--large" name="projects[${inputId}][material][]" value="${material}">
        <span class="checkbox-text">${material}</span>
      </label>
    `;
  });

  room += `
      </div>
    </div>
    <div class="form-line">
      <p class="field-group-text">Przeważający kolor</p>
      <div class="form-line__checkboxes">
  `;

  app.project_colors.forEach(color => {
    room += `
      <label class="checkbox-container checkbox-container--tile">
        <input type="checkbox" class="checkbox checkbox--large" name="projects[${inputId}][color][]" value="${color}">
        <span class="checkbox-text">${color}</span>
      </label>
    `;
  });

  room += `
      </div>
    </div>
    <div class="form-line">
      <p class="field-group-text">Kategoria</p>
      <div class="form-line__checkboxes">
  `;

  app.project_categories.forEach(category => {
    room += `
      <label class="checkbox-container checkbox-container--tile">
        <input type="checkbox" class="checkbox checkbox--large" name="projects[${inputId}][category][]" value="${category}">
        <span class="checkbox-text">${category}</span>
      </label>
    `;
  });

  room += `
      </div>
    </div>
    <div class="form-line">
      <p class="field-group-text">Przeważający styl</p>
      <div class="form-line__checkboxes">
  `;

  app.project_styles.forEach(style => {
    room += `
      <label class="checkbox-container checkbox-container--tile">
        <input type="checkbox" class="checkbox checkbox--large" name="projects[${inputId}][style][]" value="${style}">
        <span class="checkbox-text">${style}</span>
      </label>
    `;
  });

  room += `
        </div>
      </div>
      <div class="form-line">
        <label for="surface-${inputId}" class="field-label">Powierzchnia (m2)</label>
        <input type="number" class="field" name="projects[${inputId}][surface]" id="surface-${inputId}" step="any">
      </div>
      <div class="form-line">
        <label class="field-label">Wymiary pomieszczenia (cm)</label>
        <div class="range-inputs">
          <input type="number" class="field" name="projects[${inputId}][length]" placeholder="Długość" step="any">
          x
          <input type="number" class="field" name="projects[${inputId}][width]" placeholder="Szerokość" step="any">
        </div>
      </div>
      <div class="form-line">
        <label for="estimated-cost-${inputId}" class="field-label">Szacunkowy koszt wykonania remontu</label>
        <select class="field field--select" name="projects[${inputId}][estimated-cost]" id="estimated-cost-${inputId}">
          <option value="">Proszę wybrać...</option>
  `;

  app.project_estimated_costs.forEach(estimated_cost => {
    room += `<option>${estimated_cost}</option>`;
  });

  room += `
        </select>
      </div>
      <div class="form-line">
        <label for="price-${inputId}" class="field-label">Cena</label>
        <input type="number" class="field field--price" name="projects[${inputId}][price]" id="price-${inputId}" step="any">
        <span class="field-text">Nasza prowizja wynosi ${app.provision}%, czyli <span class="provision">0,00</span> zł.<br>Kwota otrzymana po zapłaceniu prowizji wyniesie <span class="amount-received">0,00</span> zł.</span>
      </div>
      <div class="form-line">
        <label class="checkbox-container">
          <input type="checkbox" class="checkbox checkbox--large" name="projects[${inputId}][with-ikea-furniture]" value="1">
          <span class="checkbox-text">Z wykorzystaniem mebli IKEA</span>
        </label>
      </div>
      <div class="form-line">
        <label class="checkbox-container">
          <input type="checkbox" class="checkbox checkbox--large" name="projects[${inputId}][accept-participation-in-competitions-consent]" value="1">
          <span class="checkbox-text">Wyrażam zgodę na udział mojego projektu w konkursach</span>
        </label>
      </div>
      <button type="button" class="remove-room button button--light">Usuń pomieszczenie</button>
    </div>
  `;

  div.innerHTML = room;

  projectList.appendChild(div);

  const tempDropzoneEls = div.querySelectorAll('.dropzone');
  const fieldPrice = div.querySelector('.field--price');
  const removeRoomButton = div.querySelector('.remove-room');

  tempDropzoneEls.forEach(el => {
    dropzones.push(initFileDrop(el));
  });

  fieldPrice.addEventListener('keyup', calculateAmountReceived);
  removeRoomButton.addEventListener('click', removeRoom);
}

export {
  handleSubmit,
  calculateAmountReceived,
  addRoom,
};
