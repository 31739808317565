/* global app */

const uploadRoomTechnicalDrawingFile = (e) => {
  const input = e.target;

  const formData = new FormData();

  formData.append('room-technical-drawing-file', input.files[0]);

  fetch(`${app.ajax_url}?action=upload-room-technical-drawing-file`, { method: 'POST', body: formData })
    .then(res => res.json())
    .then(json => {
      const formLine = input.closest('.form-line');
      const alert = formLine.querySelector('.alert');
      const roomTechnicalDrawing = formLine.querySelector('.room-technical-drawing');

      if (alert) {
        alert.remove();
      }

      if (json.success) {
        roomTechnicalDrawing.value = json.attachment_id;
      } else {
        roomTechnicalDrawing.value = '';

        let content = '';

        const ul = document.createElement('ul');

        ul.classList.add('alert', 'alert--danger');

        json.errors.forEach(error => {
          content += `<li>${error}</li>`;
        });

        ul.innerHTML = content;

        formLine.prepend(ul);
      }
    })
    .catch(err => console.error('Error:', err));
}

export { uploadRoomTechnicalDrawingFile };
